<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="5"
      >
        <b-card title="Preencha todos os campos para cadastrar seu BOT 🚀">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="createMission">
              <b-row>
                <!-- NOME DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Nome da Missão"
                    label-for="name-grupo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nome da Missao"
                      rules="required"
                    >
                      <b-form-input
                        id="name-group"
                        v-model="nome"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- LINK DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Descrição"
                    label-for="link-grupo"
                  >
                    <validation-provider
                      name="escrição"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="descricao"
                        type="text"
                        placeholder="Descrição"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- CODIGO DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Pontos"
                    label-for="link-group"
                  >
                    <validation-provider
                      name="Pontos"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="pontos"
                        type="number"
                        placeholder="Pontos"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Data de Expiração"
                    label-for="link-group"
                  >
                    <validation-provider
                      name="Data de Expiração"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="expiracao"
                        type="text"
                        placeholder="2020-01-13"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- login button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="createMission"
                  >
                    Salvar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const user = JSON.parse(localStorage.getItem('admin'))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      nome: '',
      descricao: '',
      pontos: '',
      expiracao: '',
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
    },

    createMission() {
      const body = {
        name: this.nome, description: this.descricao, points: this.pontos, status: 'ativo', date_expire: this.expiracao,
      }
      funcs.createMission(user.id, body).then(success => {
        console.log(success)
        if (success.data.error === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: success.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (success.code === 'ER_DUP_ENTRY') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Já existe cadastro dessa missao',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Verifique os campos digitados!',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
